import React, { useState, useContext, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';
import axios from 'axios';
import SymptomsContext from './SymptomsContext';
import Overlay from 'react-bootstrap/Overlay';

const store = require('store2');

const userInputStyle = {
	color: '#2D84AD',
	fontFamily: 'Aktiv Grotesk Light',
	font: "-moz-pull-down-menu",
	fontSize: '1rem'
};

let getAllBodyLocations;

let getAllBodySubLocations;

let bodyLocationSelected;

let bodySubLocationSelected;

let getPreviousYears = [];

let yearOfBirth;

let getGenderSelected;

let getGirlBoyWomanManStatus;

let personAge;

let symptomsInSubLocations;

let symptomSelectedInSubLocationId;

function CheckSymptomsClipboard(props) {
	const [bodyLocationsState, updateBodyLocationsState] = useState([]);
	const [bodySubLocationsState, updateBodySubLocationsState] = useState([]);
	const [getPreviousYearsState, updatePreviousYearsState] = useState([]);
	const [getGenderState, updateGenderState] = useState([]);
	const [getSymptomsInSubLocations, updateSymptomsInSubLocations] = useState([]);
	const response = useContext(SymptomsContext);
	const [target, setTarget] = useState(null);
	const overlayRef = useRef(null);
	const symptomSubLocationRef = useRef(null);
	const symptomYearOfBirthRef = useRef(null);
	const symptomCheckSexRef = useRef(null);
	const symptomSelectedRef = useRef(null);
	const [checkSymptoms, checkSymptomsComplete] = useState(true);
	const [checkBirthYear, checkBirthYearComplete] = useState(true);
	const [checkSelectedSex, checkSelectedSexComplete] = useState(true);
	const [checkSelectedSymptom, checkSelectedSymptomComplete] = useState(true);
	const form = document.getElementsByClassName('form');
	const getDiagnosises = (e) => {
		e.preventDefault();
		if (bodySubLocationSelected === undefined) {
			checkSymptomsComplete(false);
			setTarget(symptomSubLocationRef);
		} else if (yearOfBirth === undefined) {
			checkBirthYearComplete(false);
			setTarget(symptomYearOfBirthRef);
		} else if (getGenderSelected === null) {
			checkSelectedSexComplete(false);
			setTarget(symptomCheckSexRef);
		} else if (symptomSelectedInSubLocationId === undefined) {
			console.log('This is the symptom in that sublocation: ' + symptomSelectedInSubLocationId);
			checkSelectedSymptomComplete(false);
			setTarget(symptomSelectedRef);
		} else {
			props.onChange(props.props.className = ["checkSymptoms"]);
			let checkSymptomsResponses = response;

			async function diagnosisResponseWrapper() {
				const getDiagnosisResponses =
					axios
						.get('diagnosis?' + 'symptoms=[' + symptomSelectedInSubLocationId + ']&gender=' + getGenderSelected + '&year_of_birth=' + yearOfBirth, {
							headers: {
								userpassword: store.session.get('id')
							}
						})
						.then((response) => {
							console.log(response);
							checkSymptomsResponses = response.data;
						})
						.catch((err) => {
							console.log(err);
						})
				await getDiagnosisResponses;

				response.setResponse({ ...response.data, response: checkSymptomsResponses });
				console.log('This is the responseContext: ' + JSON.stringify(response));
			}
			diagnosisResponseWrapper();
			console.log(e);
			Array.from(document.querySelectorAll('select')).forEach(
				select => (select.value = 'DEFAULT')
			);
		}
	}
	const getBodyLocations = (e) => {
		//console.log(bodyLocationsState);
		//console.log('These are the body locations.');
		console.log('This is in the store: ' + JSON.stringify(store.session.get('id')));
		axios
			.get('getLocation', {
				headers: {
					userpassword: store.session.get('id')
				}
			})
			.then((response) => {
				console.log(response.data);
				console.log(response.data.length);
				getAllBodyLocations = response.data;
				updateBodyLocationsState(getAllBodyLocations);
			})
			.catch((err) => {
				console.log(err);
			})
		//bodyLocationSelected = e.target.childNodes[e.target.selectedIndex].getAttribute('id');
	}
	useEffect(() => {
		getBodyLocations();
		getAllBodyLocations = null;
		getAllBodySubLocations = null;
		personAge = null;
		getGenderSelected = null;
		symptomsInSubLocations = null;
	}, [])

	const getBodySubLocation = (e) => {
		bodyLocationSelected = e.target.childNodes[e.target.selectedIndex].getAttribute('id');
		(bodyLocationSelected === 'defaultSelectedBodyLocationValue') ?
			console.log('You have not selected a body location yet.')
			:
			axios
				.get('getBodySubLocations', {
					headers: {
						bodyLocation: bodyLocationSelected,
						userpassword: store.session.get('id')
					}
				})
				.then((response) => {
					getAllBodySubLocations = response.data;
					updateBodySubLocationsState(getAllBodySubLocations);
					console.log('These are the body sublocations: ' + JSON.stringify(getAllBodySubLocations));
				})
				.catch((err) => {
					console.log(err);
				});
		console.log('This is the body part getting the sublocations: ' + bodyLocationSelected);
	}
	const saveBodySubLocationSelected = (e) => {
		bodySubLocationSelected = e.target.childNodes[e.target.selectedIndex].getAttribute('id');
		checkSymptomsComplete(true);
		console.log('This is the body sublocation selected: ' + bodySubLocationSelected + ".");
		getYear();
	}
	const getYear = (e) => {
		let year = new Date().getFullYear();
		console.log(year);
		for (let i = 0; i < 120; i++) {
			getPreviousYears.push(year - i);
		}
		updatePreviousYearsState(getPreviousYears);

	}
	const assignYearOfBirth = (e) => {
		yearOfBirth = e.target.childNodes[e.target.selectedIndex].getAttribute('id');
		checkBirthYearComplete(true);
		console.log(yearOfBirth);
	}
	const getGender = (e) => {
		getGenderSelected = e.target.childNodes[e.target.selectedIndex].getAttribute('id');
		checkSelectedSexComplete(true);
		console.log(getGenderSelected);
		getGirlBoyWomanManVariable();

	}
	async function getGirlBoyWomanManVariable() {
		console.log(yearOfBirth);
		personAge = new Date().getFullYear() - yearOfBirth;
		console.log(personAge);
		const getGenderAndAgeStatus =
			personAge < 12 && getGenderSelected === 'Female' ?
				getGirlBoyWomanManStatus = "girl"
				//console.log("girl")
				:
				personAge < 12 && getGenderSelected === 'Male' ?
					getGirlBoyWomanManStatus = "boy"
					//console.log('boy')
					:
					getGenderSelected === 'Female' ?
						getGirlBoyWomanManStatus = "woman"
						//console.log('Woman')
						:
						getGirlBoyWomanManStatus = "man";
		await getGenderAndAgeStatus;
		await getSymptomsInSubLocation();
		//console.log('Man')
		console.log(getGirlBoyWomanManStatus);
	}
	const getSymptomsInSubLocation = (e) => {
		axios
			.get('getSymptomsInSubLocation/' + bodySubLocationSelected + '/' + getGirlBoyWomanManStatus, {
				headers: {
					userpassword: store.session.get('id')
				}
			})
			.then((response) => {
				console.log(response.data)
				symptomsInSubLocations = response.data;
				updateBodySubLocationsState(symptomsInSubLocations);
				console.log(symptomsInSubLocations);
			})
			.catch((err) => {
				console.log(err);
			})

	}
	const getSelectedSymptomInSubLocation = (e) => {
		symptomSelectedInSubLocationId = e.target.childNodes[e.target.selectedIndex].getAttribute('id');
		checkSelectedSymptomComplete(true);
		console.log('This is the selected symptom: ' + symptomSelectedInSubLocationId);
	}
	const checkSymptomsOverlay =
		<Overlay
			show={!checkSymptoms}
			target={target}
			placement="bottom"
			container={overlayRef.current}
		>
			<div className="warningTextOnUserInput">
				Choose where you have the symptom and the specific location.
			</div>
		</Overlay>;
	const checkYearOfBirthOverlay =
		<Overlay
			show={!checkBirthYear}
			target={target}
			placement="bottom"
			container={overlayRef.current}
		>
			<div className="warningTextOnUserInput">
				Choose the year you were born.
			</div>
		</Overlay>;
	const selectYourSexOverlay =
		<Overlay
			show={!checkSelectedSex}
			target={target}
			placement="bottom"
			container={overlayRef.current}
		>
			<div className="warningTextOnUserInput">
				Select the sex you most identify with.
			</div>
		</Overlay>;
	const selectedSymptomOverlay =
		<Overlay
			show={!checkSelectedSymptom}
			target={target}
			placement="bottom"
			container={overlayRef.current}
		>
			<div className="warningTextOnUserInput">
				Select the symptom that most resembles yours.
			</div>
		</Overlay>;
	return (
		<div id="checkSymptomsClipboard" className={props.className}>
			<Form className="form" id="checkSymptomsClipboard">
				<ClipboardClip />
				<div className="centeredPaper">
					<Form.Group controlId="ControlInput">
						<Form.Control as="select" defaultValue={'DEFAULT'} onChange={getBodySubLocation} style={userInputStyle}>
							<option disabled value="DEFAULT" id='defaultSelectedBodyLocationValue' style={userInputStyle}>
								Select Location
							</option>
							{getAllBodyLocations ?
								getAllBodyLocations.map((bodyLocation) => {
									return (
										<option id={bodyLocation.ID} key={bodyLocation.ID} style={userInputStyle}>{bodyLocation.Name}</option>
									)
								})
								:
								() => {
									return (
										<option disabled selected id='defaultSelectedBodyLocationValue' style={userInputStyle}>Waiting to load...</option>
									)
								}
							}
						</Form.Control>
					</Form.Group>
					<div ref={overlayRef}>
						{checkSymptomsOverlay}
						<Form.Group controlId="ControlInput">
							<Form.Control as="select" defaultValue={'DEFAULT'} onChange={saveBodySubLocationSelected} style={userInputStyle} ref={symptomSubLocationRef}>
								<option disabled value="DEFAULT" style={userInputStyle}>
									Select Sublocation
								</option>
								{getAllBodySubLocations ?
									getAllBodySubLocations.map((bodySubLocation) => {
										return (
											<option id={bodySubLocation.ID} key={bodySubLocation.ID} style={userInputStyle}>{bodySubLocation.Name}</option>
										)
									})
									:
									() => {
										return (
											<option style={userInputStyle}></option>
										)
									}
								}
							</Form.Control>
						</Form.Group>
					</div>
					<div ref={overlayRef}>
						{checkYearOfBirthOverlay}
						<Form.Group controlId="ControlInput">
							<Form.Control as="select" defaultValue={'DEFAULT'} onChange={assignYearOfBirth} style={userInputStyle}>
								<option disabled value="DEFAULT" style={userInputStyle} ref={symptomYearOfBirthRef}>
									Birth Year
								</option>
								{
									getPreviousYears.map((getPreviousYear) => {
										return (
											<option id={getPreviousYear} style={userInputStyle}>{getPreviousYear}</option>
										)
									})
								}
							</Form.Control>
						</Form.Group>
					</div>
					<div ref={overlayRef}>
						{selectYourSexOverlay}
						<Form.Group controlId="ControlInput">
							<Form.Control as="select" defaultValue={'DEFAULT'} onChange={getGender} style={userInputStyle}>
								<option disabled value="DEFAULT" style={userInputStyle} ref={symptomCheckSexRef}>
									Gender
								</option>
								<option id='Female' style={userInputStyle}>Female</option>
								<option id='Male' style={userInputStyle}>Male</option>
							</Form.Control>
						</Form.Group>
					</div>
					<div ref={overlayRef}>
						{selectedSymptomOverlay}
						<Form.Group controlId="ControlInput">
							<Form.Control as="select" defaultValue={'DEFAULT'} onChange={getSelectedSymptomInSubLocation} style={userInputStyle} ref={symptomSelectedRef}>
								<option disabled value="DEFAULT" style={userInputStyle}>
									Symptom
								</option>
								{symptomsInSubLocations ?
									symptomsInSubLocations.map((symptomsInSubLocation) => {
										return (
											<option id={symptomsInSubLocation.ID} style={userInputStyle}>{symptomsInSubLocation.Name}</option>
										)
									})
									:
									() => {
										return (
											<option style={userInputStyle}></option>
										)
									}
								}
							</Form.Control>
						</Form.Group>
					</div>
					<Button id="checkSymptomsSubmit" type="" onClick={getDiagnosises}>
						Diagnosis
					</Button>
				</div>
			</Form>
		</div>
	);
}

export default CheckSymptomsClipboard;
