import React, { useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import FloatingLabelInput from 'react-floating-label-input';
import ClipboardClip from './../ClipboardClip';
import axios from 'axios';
import { ThemeProvider } from "styled-components";

const userInputStyle = {
    color: "#2D84AD",
    fontFamily: 'Aktiv Grotesk Light'
}

const params = new URLSearchParams(window.location.search);
//console.log(params.get('passwordreset'));
const passwordReset = params.get('passwordreset');

function GetPasswordReset(props) {
    const [user, setUser] = useState({
        loggedIn: false,
        email: '',
        password: '',
        firstName: ''
    }
    );
    const ref = useRef(null);
    const [target, setTarget] = useState(null);
    const [userEmail, userEmailValid] = useState(true);
    const [userPassword, userPasswordValid] = useState(true);
    const regExEmail = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    const handleEmailChange = (event) => {
        setUser({
            email: event.target.value,
            password: user.password,
            firstName: user.firstName,
            lastName: user.lastName
        });
    };
    const handleEmailOnBlur = (event) => {
        if (regExEmail.test(user.email) === false) {
            userEmailValid(false);
            setTarget(event.target);
        } else if (regExEmail.test(user.email) === true) {
            userEmailValid(true);
        }
    }
    const regExPassword = RegExp(/(?=^.{10,})((?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]{4,})(?=.*[@|!$&_]))/);
    const handlePasswordChange = (event) => {
        setUser({
            email: user.email,
            password: event.target.value,
            firstName: user.firstName,
            lastName: user.lastName
        });
    };
    const handlePasswordOnBlur = (event) => {
        if (regExPassword.test(user.password) === false) {
            userPasswordValid(false);
            setTarget(event.target);
        } else if (regExPassword.test(user.password) === true) {
            userPasswordValid(true);
        }
    }
    const handleClick = (e) => {
        e.preventDefault();
        //console.log(props.props.user);
        const newPassword = user.password;
        const email = user.email;
        if (regExEmail.test(user.email) === false) {
            userEmailValid(false);
            setTarget(document.getElementById("resettingUserEmail"));
        } else if (regExEmail.test(user.email) === true) {
            userEmailValid(true);
            if (regExPassword.test(user.password) === false) {
                userPasswordValid(false);
                setTarget(document.getElementById("newpassword"));
            } else if (regExPassword.test(user.password) === true) {
                userPasswordValid(true);
                axios
                    .post('newpassword', { newPassword, passwordReset, email })
                    .then((response) => {
                        //console.log(response.data);
                        //console.log(response.data.affectedRows);
                        //console.log(response.data.affectedRows === 1);
                        if (response.data === "You cannot update your password to you new password. Please use a different password.") {
                            errorOnResetPasswordNotComplete();

                        } else if (response.data.affectedRows === 1) {
                            passwordResetSuccess();
                            //console.log(props.props);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }
    const errorOnResetPasswordNotComplete = () => {
        props.onChange(props.props.className = ["errorOnResetPasswordNotComplete"]);
    }
    const passwordResetSuccess = () => {
        props.onChange(props.props.className = ["logInClipboardClassName"]);
    }

    window.history.replaceState({}, document.title, "/");
    const emailOverlay =
        <Overlay
            show={!userEmail}
            target={target}
            placement="bottom"
            container={ref.current}
        >
            <div className="warningTextOnUserInput">
                Please enter an email address that matches an email pattern.
          	</div>
        </Overlay>;
    const passwordOverlay =
        <Overlay
            show={!userPassword}
            target={target}
            placement="bottom"
            container={ref.current}
        >
            <div className="warningTextOnUserInput">
                Please enter a password that contains at least four numbers, one special character, and at least 10 characters.
          	</div>
        </Overlay>;
    return (
        <div className={props.className} id="resetPasswordClipboard">
            <Form className="form" >
                <ClipboardClip></ClipboardClip>
                <div className="centeredPaper">
                    <div ref={ref}>
                        {emailOverlay}
                        <Form.Group controlId="ControlInput">
                            <FloatingLabelInput
                                id="resettingUserEmail"
                                label="Email"
                                type="email"
                                name="resettingUserEmail"
                                className="changeColor"
                                style={userInputStyle}
                                onChange={handleEmailChange}
                                onBlur={handleEmailOnBlur}
                            />
                        </Form.Group>
                    </div>
                    <div ref={ref}>
                        {passwordOverlay}
                        <Form.Group controlId="ControlInput">
                            <FloatingLabelInput
                                id="newpassword"
                                label="New Password"
                                type="password"
                                name="newpassword"
                                className="changeColor"
                                style={userInputStyle}
                                onChange={handlePasswordChange}
                                onBlur={handlePasswordOnBlur}
                            />
                        </Form.Group>
                    </div>
                    <Button id="resetPassword" type="" onClick={handleClick}>
                        Change Password
                        </Button>
                </div>
            </Form>
        </div>
    );

}

export default GetPasswordReset;
