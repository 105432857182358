import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';
import PrivacyPolicyText from './PrivacyPolicyText';
import axios from 'axios';

function PrivacyPolicy(props) {
    const [userInputState, setUserInputState] = useState({value:''});
    function handleChange(event) {
        setUserInputState({
            value: event.target.value
        });
    };
    return (
        <div id="privacyPolicyClipboard" className={props.className}>
            <Form className="form" >
                <ClipboardClip />
                <div className="centeredPaper">
                    <PrivacyPolicyText />
                </div>
            </Form>
        </div>
    );


}
export default PrivacyPolicy;
