import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';
import axios from 'axios';
import SymptomsContext from './SymptomsContext';
import TreatmentContext from './TreatmentContext';

const store = require('store2');

let symptoms;

function CheckSymptoms(props) {
	const response = useContext(SymptomsContext);
	const treatmentResponse = useContext(TreatmentContext);
	const handleSymptomClick = (e) => {
		e.preventDefault();
		console.log(e.target.getAttribute('id'));
		props.onChange(props.props.className = ["showTreatments"]);  
		let treatmentResponses = treatmentResponse;
		async function treatmentResponseWrapper() {
			const treatmentResponseFunction =
				axios
					.get('issues/' + e.target.getAttribute('id') + '/info', {
						headers: {
							userpassword: store.session.get('id')
						}
					})
					.then((response) => {
						console.log(response.data.TreatmentDescription);
						treatmentResponses = response.data;
					})
					.catch((err) => {
						console.log(err);
					})
			await treatmentResponseFunction;
			treatmentResponse.setResponse({ ...treatmentResponse.data, response: treatmentResponses });
			console.log('This is the treatmentContext: ' + JSON.stringify(treatmentResponse));
		}
		treatmentResponseWrapper();

	};
	symptoms = response.data.response;
	return (
		<div id="showSymptomsClipboard" className={props.className}>
			<Form className="form" >
				<ClipboardClip />
				<div className="centeredPaper">
					<Button
						style={{
							backgroundColor: '#FF6C35',
							borderColor: 'transparent',
							fontFamily: "Aktiv Grotesk Extended Bold",
							marginTop: ' 1em',
							width: '94%'
						}}
					>Diagnosis | Accuracy</Button>
					{response.data === 'Hello World'
						?
						<div>
							<p>Loading...</p>
						</div>
						:
						<div>
							{Object.keys(symptoms).map(symptom => {
								return (
									<Button
										key={symptoms[symptom].Issue.ID}
										id={symptoms[symptom].Issue.ID}
										label={symptom}
										type={symptom}
										name={symptom}
										style={{
											backgroundColor: '#FF6C35',
											borderColor: 'transparent',
											fontFamily: "Aktiv Grotesk Extended Bold",
											marginTop: ' 1em',
											width: '94%'
										}}
										value={symptom}
										onClick={handleSymptomClick}
									>{symptoms[symptom].Issue.Name} | {symptoms[symptom].Issue.Accuracy}%</Button>
								)
							})}
							<br />
						</div>
					}
				</div>
			</Form>
		</div>
	);
}

export default CheckSymptoms;