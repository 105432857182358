import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';
import axios from 'axios';

function ThankYouForYourFeedback(props) {
    const handleClick = (e) => {  
        e.preventDefault();
        props.onChange(props.props.className = [""]);//Provide a thank you for providing your feedback clipboard right here. 
    };
    return (
        <div id="thankYouForYourFeedbackClipboard" className={props.className}>
            <Form className="form" >
                <ClipboardClip />
                <div className="centeredPaper">
                    <p>Thank you for your feedback.
                        We will reach out to you if we need any further information
                        or if we would like to use your recommendation in our reviews.</p>
                    <Button id="checkSymptomsDataDismiss" type="" onClick={handleClick} >
                        Thank You
					</Button>
                </div>
            </Form>
        </div>
    );


}
export default ThankYouForYourFeedback;