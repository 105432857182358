import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-150831194-2"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID, {
    gaOptions: {
        siteSpeedSampleRate: 100
    }
});

ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(<App />, document.getElementById('root')); document.body.style = 'background: #ECFBFF;';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

