import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';
import axios from 'axios';

function ErrorFromYourFeedback(props) {
    const handleClick = (e) => {  
        e.preventDefault();
        props.onChange(props.props.className = [""]);//Provide an error message right here.
    };
    return (
        <div id="errorFromYourFeedbackClipboard" className={props.className}>
            <Form className="form" >
                <ClipboardClip />
                <div className="centeredPaper">
                    <p>We encountered an error when processing your feedback.
                        Can you complete the form again and resubmit?
                    </p>
                    <Button id="checkSymptomsDataDismiss" type="" onClick={handleClick} >
                        Apologies
					</Button>
                </div>
            </Form>
        </div>
    );


}
export default ErrorFromYourFeedback;