import React, { useState } from 'react';
import {ReCaptchaProvider} from 'react-recaptcha-x';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HealthOneNavbar from './components/HealthOneNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAccessibleIcon } from '@fortawesome/free-brands-svg-icons';
const store = require('store2');

function App() {
	const params = new URLSearchParams(window.location.search);
	//console.log(params.get('passwordreset'));
	const passwordReset = params.get('passwordreset');
	/*console.log('This is the state of the store: ' + store.session.get('id'));
	console.log('This is the id that you stored in the session: ' + JSON.stringify(store.session.get('id')));*/
	const overcomingState = passwordReset ? {
		user: {
			loggedIn: store.session.get('id'),
			email: store.session.get('email'),
			password: '',
			firstName: store.session.get('firstName')
		},
		className: ['newPasswordClassName'],
	} : {
		user: {
			loggedIn: store.session.get('id'),
			email: store.session.get('email'),
			password: '',
			firstName: store.session.get('firstName')
		},
		className: [],
	};
	const [currentState, setCurrentState] = useState(overcomingState);
	function findCurrentState(currentAppState) {
		setCurrentState(currentAppState);
	}
	//console.log(currentState);
	return (
		<ReCaptchaProvider
			siteKeyV2="6LcfhoscAAAAACuaGE1t8H-n6cJC4A6xwZiVsIho"
			siteKeyV3="6LdiIl4cAAAAAHnaolOgHkaYO7YFP3l1TUrlC7Y5"
			langCode="en"
			hideV3Badge={false}
		>
			<div className="App">
				<HealthOneNavbar onChange={findCurrentState} props={currentState} />
			</div>
		</ReCaptchaProvider>
	);
}

export default App;
