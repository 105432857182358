import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';

class ErrorForResettingPassword extends React.Component{  
    constructor(props){
        super(props);
        this.errorForResettingPassword = this.errorForResettingPassword.bind(this);
    }
    //const currentUser = props.props.user;
    errorForResettingPassword = (e) =>{
        e.preventDefault();
        this.props.onChange(this.props.props.className = ['resetClipboardClassName']);
        
    }
    render(){
        return(
            <div className={this.props.className} id="errorForResettingPassword">
                <Form className="form">
                    <ClipboardClip/>
                    <div className="centeredPaper">
                        <p>Dang it! Something went wrong. Please try again.</p>
                        <Button id="errorForResettingPasswordButton" type="" onClick={this.errorForResettingPassword}>Okay</Button>
                    </div>
                </Form>
            </div>
    
        );
    }
};

export default ErrorForResettingPassword;