import React, { useState, useRef, forwardRef, useFocus } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import FloatingLabelInput from 'react-floating-label-input';
import { ReCaptchaV3 } from 'react-recaptcha-x';

import ClipboardClip from './../ClipboardClip';
import axios from 'axios';

const userInputStyle = {
	color: '#2D84AD',
	fontFamily: 'Aktiv Grotesk Light'
};
let v3Token;
let v3GoogleRecaptchaScore;
function RegisterClipboard(props) {
	const [user, setUser] = useState({
		firstName: '',
		lastName: '',
		email: '',
		password: ''
	});
	const [firstName, firstNameValid] = useState(true);
	const [lastName, lastNameValid] = useState(true);
	const [userEmail, userEmailValid] = useState(true);
	const [userPassword, userPasswordValid] = useState(true);
	const [target, setTarget] = useState(null);
	const overlayRef = useRef(null);

	const v3Callback = (token, refreshToken) => {
		if (typeof token === 'string') {
			//console.log('this is the v3 token', token);
			/* This block of code gets the recaptcha to fail?
			* console.log('This is the reCaptcha key in local storage: ' + localStorage.getItem('_grecaptcha'));
			*/
			v3Token = token;
			setTimeout(function(){ refreshToken(); }, 120000);
		}
	};

	const regExName = RegExp(/^([a-zA-Z-]+'?[^. \ + * ? [ ^ \] \$ ( ) { } = ! < > | :@])$/);
	const regExEmail = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
	const regExPassword = RegExp(/(?=^.{10,})((?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]{4,})(?=.*[@|!$&_]))/);
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const email2Ref = useRef(null);
	const registerPasswordRef = useRef(null);
	async function verifyGoogle3Captcha() {
		const getTheGoogleScore =
			axios
				.post('googleCaptchaVerify?response=' + v3Token)
				.then((response) => {
					v3GoogleRecaptchaScore = response.data.score;
				})
				.catch((error) => {
					console.log(error);
				})
		await getTheGoogleScore;
	};
	const handleFirstNameChange = (event) => {
		setUser({
			email: user.email,
			password: user.password,
			firstName: event.target.value,
			lastName: user.lastName
		});
	};
	const handleFirstNameOnBlur = () => {
		if (regExName.test(user.firstName) === false) {
			firstNameValid(false);
			setTarget(firstNameRef);
		} else if (regExName.test(user.firstName) === true) {
			firstNameValid(true);
			setTarget(lastNameRef);
		}
	}
	const handleLastNameChange = (event) => {
		setUser({
			email: user.email,
			password: user.password,
			firstName: user.firstName,
			lastName: event.target.value
		});
	};
	const handleLastNameOnBlur = () => {
		if (regExName.test(user.lastName) === false) {
			lastNameValid(false);
			setTarget(lastNameRef);
		} else if (regExName.test(user.lastName) === true) {
			lastNameValid(true);
			setTarget(email2Ref);
		}
	}
	const handleEmailChange = (event) => {
		setUser({
			email: event.target.value,
			password: user.password,
			firstName: user.firstName,
			lastName: user.lastName
		});
	};
	const handleEmailOnBlur = () => {
		if (regExEmail.test(user.email) === false) {
			userEmailValid(false);
			setTarget(email2Ref);
		} else if (regExEmail.test(user.email) === true) {
			userEmailValid(true);
			setTarget(registerPasswordRef);
		}
	}
	const handlePasswordChange = (event) => {
		setUser({
			email: user.email,
			password: event.target.value,
			firstName: user.firstName,
			lastName: user.lastName
		});
	};
	const handlePasswordOnBlur = () => {
		if (regExPassword.test(user.password) === false) {
			userPasswordValid(false);
			setTarget(registerPasswordRef);
		} else if (regExPassword.test(user.password) === true) {
			userPasswordValid(true);
		}
	}
	const completeYourRegistrationClipboard = () => {
		props.onChange(props.props.className = ["completeYourRegistrationClipboard"]);
	}
	const registrationNeedsCompletion = () => {
		props.onChange(props.props.className = ["registrationNeedsCompletion"]);
	}
	const somethingWentWrongWithYourRegistration = () => {
		props.onChange(props.props.className = ["somethingWentWrongWithYourRegistration"])
	}
	const handleClick = (e) => {
		e.preventDefault();
		const registerParameters = user;
		async function awaitUserInputValidation() {
			await verifyGoogle3Captcha();
			await console.log('This is the google recaptcha score after the await for the register component: ' + v3GoogleRecaptchaScore);
			async function checkScore() {
				//console.log("This is the checkscore function");
				if (v3GoogleRecaptchaScore < 0.1 || v3GoogleRecaptchaScore === undefined) {
					somethingWentWrongWithYourRegistration();
				} else if (v3GoogleRecaptchaScore >= 0.1) {
					if (regExName.test(user.firstName) === false) {
						handleFirstNameOnBlur();
					} else if (regExName.test(user.firstName) === true) {
						firstNameValid(true);
						if (regExName.test(user.lastName) === false) {
							handleLastNameOnBlur();
						} else if (regExName.test(user.lastName) === true) {
							lastNameValid(true);
							if (regExEmail.test(user.email) === false) {
								handleEmailOnBlur();
							} else if (regExEmail.test(user.email) === true) {
								userEmailValid(true);
								if (regExPassword.test(user.password) === false) {
									handlePasswordOnBlur();
								} else if (regExPassword.test(user.password) === true) {
									userPasswordValid(true);
									axios
										.post('register', registerParameters)
										.then((response) => {
											if (response.data.code === 'ER_DUP_ENTRY') {
												registrationNeedsCompletion();
											} else if (response.data.affectedRows === 1) {
												completeYourRegistrationClipboard();
											} else {
												somethingWentWrongWithYourRegistration();// 
											}
										})
										.catch((err) => {
											console.log(err);
										});
									setUser({
										firstName: '',
										lastName: '',
										email: '',
										password: ''
									});
									await registerPasswordRef.current.focus();
									await email2Ref.current.focus();
									await lastNameRef.current.focus();
									await firstNameRef.current.focus();
								}
							}
						}
					}
				}
			}
			await checkScore();
		}
		awaitUserInputValidation();
	}
	const firstNameOverlay =
		<Overlay
			show={!firstName}
			target={target}
			placement="bottom"
			container={overlayRef.current}
		>
			<div className="warningTextOnUserInput">
				For your first name, please enter at least two characters with letters, hyphens, and apostrophes.
			</div>
		</Overlay>;
	const lastNameOverlay =
		<Overlay
			show={!lastName}
			target={target}
			placement="bottom"
			container={overlayRef.current}
		>
			<div className="warningTextOnUserInput">
				For your last name, please enter at least two characters with letters, hyphens, and apostrophes.
			</div>
		</Overlay>;
	const emailOverlay =
		<Overlay
			show={!userEmail}
			target={target}
			placement="bottom"
			container={overlayRef.current}
		>
			<div className="warningTextOnUserInput">
				Please enter an email address that matches an email pattern.
			</div>
		</Overlay>;
	const passwordOverlay =
		<Overlay
			show={!userPassword}
			target={target}
			placement="bottom"
			container={overlayRef.current}
		>
			<div className="warningTextOnUserInput">
				Please enter a password that contains at least four numbers, one special character, and at least 10 characters.
			</div>
		</Overlay>;
	return (
		<div className={props.className} id="registerClipboard">
			<Form className="form">
				<ClipboardClip />
				<div className="centeredPaper">
					<div ref={overlayRef}>
						{firstNameOverlay}
						<Form.Group controlId="firstName" >
							<FloatingLabelInput
								id="firstName"
								label="First Name"
								type="firstName"
								name="firstName"
								className="changeColor"
								refs={firstNameRef}
								style={userInputStyle}
								value={user.firstName}
								onChange={handleFirstNameChange}
								onBlur={handleFirstNameOnBlur}
							/>
						</Form.Group>
					</div>
					<div ref={overlayRef}>
						{lastNameOverlay}
						<Form.Group controlId="lastName">
							<FloatingLabelInput
								id="lastName"
								label="Last Name"
								type="lastName"
								name="lastName"
								className="changeColor"
								refs={lastNameRef}
								style={userInputStyle}
								value={user.lastName}
								onChange={handleLastNameChange}
								onBlur={handleLastNameOnBlur}
							/>
						</Form.Group>
					</div>
					<div ref={overlayRef}>
						{emailOverlay}
						<Form.Group controlId="ControlInput">
							<FloatingLabelInput
								id="email2"
								label="Email"
								type="email"
								name="email"
								className="changeColor"
								refs={email2Ref}
								style={userInputStyle}
								value={user.email}
								onChange={handleEmailChange}
								onBlur={handleEmailOnBlur}
							/>
						</Form.Group>
					</div>
					<div ref={overlayRef}>
						{passwordOverlay}
						<Form.Group controlId="formBasicPassword">
							<FloatingLabelInput
								id="registerPassword"
								label="Password"
								type="password"
								name="password"
								value={user.password}
								refs={registerPasswordRef}
								onChange={handlePasswordChange}
								onBlur={handlePasswordOnBlur}
							/>
						</Form.Group>
					</div>
					<div ref={overlayRef}>
						{firstNameOverlay}
						{lastNameOverlay}
						{emailOverlay}
						{passwordOverlay}
						<ReCaptchaV3 action="submit" callback={v3Callback} />
						<Button id="registerSubmit" type="submit" onClick={handleClick}>
							Register
						</Button>
					</div>
				</div>
			</Form>
		</div>
	);
}
export default RegisterClipboard;
