import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';
import PrivacyPolicyText from './PrivacyPolicyText';
import axios from 'axios';

const store = require('store2');

let email;

function PrivacyPolicyForRegistration(props) {
    const [userInputState, setUserInputState] = useState({ value: '' });
    function handleChange(event) {
        setUserInputState({
            value: event.target.value
        });
    };
    const handleFeedbackClick = (e) => {
        e.preventDefault();
        //Provide a thank you for providing your feedback clipboard right here. 
        console.log(userInputState);
        async function feedbackWrapper() {
            const feedback =
                axios
                    .post('feedback', userInputState, {
                        headers: {
                            userpassword: store.session.get('id')
                        }
                    })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            await feedback;
            function clearForm() {
                setUserInputState({ value: '' });
            }
            await clearForm();
            //document.getElementById('text-entry-field').value='';
        }
        feedbackWrapper();
    };
    const handleClick = (e) => {
        e.preventDefault();
        props.onChange(props.props.className = [""]);
        email = store.session.get('email');
        axios
            .post('agreeToPrivacyAgreement', {
                email: email
            }, {
                headers: {
                    userpassword: store.session.get('id')
                }
            })
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            })
    };
    const handleDisagreeClick = (e) => {
        e.preventDefault();
        props.onChange(props.props.className = ["logOutForDisagreement"]);
    }
    useEffect(() => {
        email = store.session.get('email');
        console.log('This is the email before the setTimeout button:' + email);
        //I might need to make this into an await later. 
        props.onChange(props.props.className = [""]);
        axios
            .post('privacyAgreed', {
                email: email
            }, {
                headers: {
                    userpassword: store.session.get('id')
                }
            })
            .then((response) => {
                console.log(response);
                if (response.data === false) {
                    setTimeout(function () {
                        props.onChange(props.props.className = ["privacyPolicyClipboardAgreement"]);
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])
    return (
        <div id="privacyPolicyClipboardLoggedIn" className={props.className}>
            <Form className="form" onSubmit={handleFeedbackClick}>
                <ClipboardClip />
                <div className="centeredPaper">
                    <p>You only need to agree to the privacy policy once.
                    We keep it for our records afterwards and keep it available on our site for your reference.
                    </p>
                    <PrivacyPolicyText />
                    <span id="hcycuatp"></span>
                    <h2>How can you contact us about this policy?</h2>
                    <p>If you have questions or comments about this policy, you can provide feedback below.</p>
                    <textarea style={{ borderColor: "#2D84AD", borderRadius: "1em", backgroundColor: "#ECFBFF", width: "100%", outline: "none" }} className="feedback" id="text-entry-field" onChange={handleChange} value={userInputState.value} />
                    <Button id="feedbackOnPrivacyPolicyAgreementRegistering" type="">
                        Send Feedback
					</Button>
                    <Button id="privacyPolicyAgreement" type="" onClick={handleClick}>
                        Agree
					</Button>
                    <Button id="privacyPolicyDisagree" type="" onClick={handleDisagreeClick}>
                        Don't Agree
					</Button>
                </div>
            </Form>
        </div>
    );


}
export default PrivacyPolicyForRegistration;
