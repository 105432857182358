
import React from 'react'
class ClipboardClip extends React.Component{
    render(){
        return(
            <div className="clipboardClip">
                <div className="clipboardTop"></div>
                <div className="clipboardBend"></div>
            </div>

        )
    }
}
export default ClipboardClip;