import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';

class IncorrectUsernameOrPassword extends React.Component{
    constructor(props){
        super(props);
        this.handleClickLogginFailure = this.handleClickLogginFailure.bind(this);
    }
    //const currentUser = props.props.user;
    handleClickLogginFailure = (e) =>{
        e.preventDefault();//something
        
        this.props.onChange(this.props.props.className = ["logInClipboardClassName"]);
        
    }
    render(){
        return(
            <div className={this.props.className} id="incorrectLogIn">
                <Form className="form">
                    <ClipboardClip/>
                    <div className="centeredPaper">
                        <p>Your username or password is incorrect. Please try to log in again.</p>
                        <Button id="incorrectLoginDismiss" type="" onClick={this.handleClickLogginFailure}>Dismiss</Button>
                    </div>
                </Form>
            </div>
    
        );
    }
};

export default IncorrectUsernameOrPassword;