import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';

function NoRegisteredUser(props) {
    const handleClick = (e) => {
        e.preventDefault();
        props.onChange(
            props.props.className = ['registerClipboardClassName']
        );
    };
    return (
        <div id="noRegisteredUserClipboard" className={props.className}>
            <Form className="form">
                <ClipboardClip />
                <div className="centeredPaper">
                    <p>You're not registered. Do you want to register?</p>
                    <Button id="noRegisteredUser" type="" onClick={handleClick}>
                        Register
					</Button>
                </div>
            </Form>
        </div>
    );
}

export default NoRegisteredUser;