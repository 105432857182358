import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';

class SomethingWentWrongWithYourRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.somethingWentWrongWithYourRegistration = this.somethingWentWrongWithYourRegistration.bind(this);
    }
    //const currentUser = props.props.user;
    somethingWentWrongWithYourRegistration = (e) => {
        e.preventDefault();
        this.props.onChange(this.props.props.className = [""]);

    }
    render() {
        return (
            <div className={this.props.className} id="somethingWentWrongWithYourRegistration">
                <Form className="form">
                    <ClipboardClip />
                    <div className="centeredPaper">
                        <p>Dang it! Something went wrong with your registration. Please try again.</p>
                        <Button id="somethingWentWrongWithYourRegistrationButton" type="" onClick={this.somethingWentWrongWithYourRegistration}>Okay</Button>
                    </div>
                </Form>
            </div>

        );
    }
};

export default SomethingWentWrongWithYourRegistration;