import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';
import axios from 'axios';

const store = require('store2');

function FeedbackForm(props) {
    const [userInputState, setUserInputState] = useState({ value: '' });
    const handleClick = (e) => {
        e.preventDefault();
        //Provide a thank you for providing your feedback clipboard right here. 
        console.log(userInputState);
        async function feedbackWrapper() {
            const feedback =
                axios
                    .post('feedback', userInputState, {
                        headers: {
                            userpassword: store.session.get('id')
                        }
                    })
                    .then((response) => {
                        console.log(response);
                        if (response.data === "Error") {
                            props.onChange(props.props.className = ["errorFromYourFeedback"]);
                        } else {
                            props.onChange(props.props.className = ["thankYouForYourFeedback"]);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            await feedback;
            function clearForm() {
                setUserInputState({ value: '' });
            }
            await clearForm();
            //document.getElementById('text-entry-field').value='';
        }
        feedbackWrapper();

    };
    function handleChange(event) {
        setUserInputState({
            value: event.target.value
        });
    }
    return (
        <div id="feedbackClipboard" className={props.className}>
            <Form className="form" onSubmit={handleClick}>
                <ClipboardClip />
                <div className="centeredPaper">
                    <p>Please leave your feedback.</p>
                    <textarea
                        style={{
                            borderColor: "#2D84AD",
                            borderRadius: "1em",
                            backgroundColor: "#ECFBFF",
                            width: "100%",
                            outline: "none"
                        }}
                        className="feedback"
                        id="text-entry-field"
                        onChange={handleChange}
                        value={userInputState.value}
                        autoFocus = {
                            props.props.className.includes('feedbackForm') ? (
                                true
                            ) : (
                                false
                            )
                        }
                    />
                    <Button id="checkSymptomsDataDismiss" type="submit" >
                        Send Feedback
					</Button>
                </div>
            </Form>
        </div>
    );


}
export default FeedbackForm;
