import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';

class ResetPasswordFailure extends React.Component{
    constructor(props){
        super(props);
        this.resetPasswordFailure = this.resetPasswordFailure.bind(this);
    }
    //const currentUser = props.props.user;
    resetPasswordFailure = (e) =>{
        e.preventDefault();
        this.props.onChange(this.props.props.className = ['registerClipboardClassName']);
        
    }
    render(){
        return(
            <div className={this.props.className} id="resetPasswordFailure">
                <Form className="form">
                    <ClipboardClip/>
                    <div className="centeredPaper">
                        <p>We don't have record of you. Can you check your email address to register or register if you haven't already?</p>
                        <Button id="resetPasswordFailureButton" type="" onClick={this.resetPasswordFailure}>Okay</Button>
                    </div>
                </Form>
            </div>
    
        );
    }
};

export default ResetPasswordFailure;