import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';
import axios from 'axios';
import TreatmentContext from './TreatmentContext';

let treatments;

function ShowTreatments(props) {
    const treatementResponse = useContext(TreatmentContext);
    const handleClick = (e) => {
        e.preventDefault();
        props.onChange(props.props.className = [""]);  
    };
    treatments = treatementResponse.data.response;
    return (
        <div id="showTreatmentsClipboard" className={props.className}>
            <Form className="form" >
                <ClipboardClip />
                <div className="centeredPaper">
                    {treatementResponse.data === 'Hello Fucking World'
                        ?
                        <div>
                            <p>Loading...</p>
                        </div>
                        :
                        <div>
                            <p>{JSON.stringify(treatments.TreatmentDescription)}</p>
                        </div>
                    }
                    <Button id="checkSymptomsDataDismiss" type="" onClick={handleClick} >
                        Okay
					</Button>
                </div>
            </Form>
        </div>
    );


}
export default ShowTreatments;
