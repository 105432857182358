
import React, { useState, useRef } from 'react';
//This is going to the component later
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//This is going in the floating label component. I'm not sure it goes in the Form Component.
import FloatingLabelInput from 'react-floating-label-input';
import { ReCaptchaV3 } from 'react-recaptcha-x';
import ClipboardClip from './../ClipboardClip';
import axios from 'axios';

const myStyle = {
  color: "#2D84AD",
  fontFamily: 'Aktiv Grotesk Light'
}

let v3Token;
let v3GoogleRecaptchaScore;

function ResetClipboard(props) {
  const [state, setState] = useState({
    user: {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    },
    className: ["resetClipboardClassName"]
  });
  const emailRef = useRef();
  const v3Callback = (token, refreshToken) => {
    if (typeof token === 'string') {
      //console.log('This is the v3 token in the reset component: ', token);
      /* This block of code gets the recaptcha to fail?
      * console.log('This is the reCaptcha key in local storage: ' + localStorage.getItem('_grecaptcha'));
      */
      v3Token = token;
      setTimeout(function(){ refreshToken(); }, 120000);
    }
  };
  async function verifyGoogle3Captcha() {
		const getTheGoogleScore =
			axios
				.post('googleCaptchaVerify?response=' + v3Token)
				.then((response) => {
					v3GoogleRecaptchaScore = response.data.score;
				})
				.catch((error) => {
					console.log(error);
				})
		await getTheGoogleScore;
	};
  const handleEmailChange = (event) => {

    setState({
      user: {
        firstName: '',
        lastName: '',
        email: event.target.value,
        password: ''
      },
      className: ["resetClipboardClassName"]
    });
  };
  const resetConfirmation = () => {
    props.onChange(props.props.className = ["resetConfirmationClipboard"]);
  };
  const resetPasswordFailure = () => {
    props.onChange(props.props.className = ["resetPasswordFailureClipboard"]);
  };
  const errorForResettingPassword = () => {
    props.onChange(props.props.className = ["errorForResettingPassword"]);
    setState({
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      },
      className: ["resetClipboardClassName"]
    });
  };
  const handleClick = (e) => {
    e.preventDefault();
    const resetParameters = state;
    async function resetPasswordWithCaptcha() {
      await verifyGoogle3Captcha();
      await console.log('This is the google recaptcha score after the await for the reset component: ' + v3GoogleRecaptchaScore);
      async function checkscore() {
        if (v3GoogleRecaptchaScore < 0.1 || v3GoogleRecaptchaScore === undefined) {
          console.log("This is the error if something goes wrong with your password reset attempt.");
          console.log("This is the captcha score in the if function: " + v3GoogleRecaptchaScore);
          resetPasswordFailure();
        } else if (v3GoogleRecaptchaScore >= 0.1) {
          axios
            .post('resetPassword', resetParameters)
            .then((response) => {
              const notARegisteredUser = "User not found";
              if (response.data === notARegisteredUser) {
                resetPasswordFailure();
              } else if (response.data[0].PasswordReset) {
                resetConfirmation();
              } else {
                errorForResettingPassword();
              }
            })
            .catch((error) => {
              console.log(error)
            })
          setState({
            user: {
              loggedIn: state.user.loggedIn,
              email: '',
              password: '',
              firstName: state.user.firstname
            },
            className: props.props.className,
          });
          emailRef.current.focus();
        }
      }
      await checkscore();
    }
    resetPasswordWithCaptcha();
  }
  return (
    <div className={props.className} id="resetClipboardClassName" >
      <Form className="form" >
        <ClipboardClip></ClipboardClip>
        <div className="centeredPaper">
          <Form.Group controlId="ControlInput">
            <FloatingLabelInput
              id="resetEmail"
              label="Email"
              type="email"
              name="resetEmail"
              className="changeColor"
              style={myStyle}
              onChange={handleEmailChange}
              value={state.user.email}
              refs={emailRef}
            />
          </Form.Group>
          <ReCaptchaV3 action="submit" callback={v3Callback} />
          <Button id="resetSubmit" type="submit" onClick={handleClick}>
            Reset Password
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default ResetClipboard;