import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabelInput from 'react-floating-label-input';
import ClipboardClip from './../ClipboardClip';
import axios from 'axios';
import { json } from 'body-parser';
import responseContext from './ResponsesContext';

const store = require('store2');

function CheckPrescriptionClipboard(props) {
	const [drugs, updateDrugs] = useState({ drug1: "" });
	const [pages, updatePages] = useState([1]);
	const [pageSwitch, updatePageSwitch] = useState([1]);
	const response = useContext(responseContext);
	const handleClick = (e) => {
		e.preventDefault();
		props.onChange(props.props.className = ["checkInteractionResponses"]);
		console.log(drugs);
		console.log('This is the orignal response: ' + JSON.stringify(response));
		let drugInteractionsResponse;
		async function getReponsesFromInformationProviders() {
			const getResponsesFromInformationProvidersWrapper =
				axios
					.post('getPrescriptionInteractions', drugs, {
						headers: {
							userpassword: store.session.get('id')
						}
					})
					.then((response) => {
						drugInteractionsResponse = response.data;
					})
					.catch((error) => {
						console.log(error);
					});
			await getResponsesFromInformationProvidersWrapper;
			response.setResponse({ ...response.data, response: drugInteractionsResponse });
			console.log('This is the final response: ' + JSON.stringify(response));
			console.log('This is the last log of the data that you get back: ' + JSON.stringify(drugInteractionsResponse));
		}
		getReponsesFromInformationProviders();
		updateDrugs({ drug1: "" });
		updatePages([1]);
		updatePageSwitch([1]);
		/*const emptyDrugFields = () => {
			document.getElementById("empty-drug-fields").reset();
		}
		emptyDrugFields();*/


	};

	const appendDrug = (e) => {
		e.preventDefault();
		const drugsSize = Object.keys(drugs).length + 1;
		console.log(drugsSize);
		const newDrug = Object.assign({}, { ...drugs, ['drug' + drugsSize]: '' });
		console.log('This is the new drug: ' + JSON.stringify(newDrug));
		if (drugsSize === 1 || drugsSize % 2 === 0) {
			updateDrugs(newDrug);
			console.log(drugs);
		} else if (drugsSize % 2 === 1) {
			console.log('This starts the pageSwitch: ' + pageSwitch[pageSwitch.length - 1]);
			updatePageSwitch(pageSwitch => [...pageSwitch, (pageSwitch[pageSwitch.length - 1] + 1)]);
			updatePages(pages => [...pages, pages[pages.length - 1] + 1]);
			console.log(pages);
			console.log(Array.isArray(pages));
			updateDrugs(newDrug);
			console.log('These are the drugs in the else if block: ' + JSON.stringify(newDrug));
			console.log('This is the pageSwitch in the else-if block: ' + pageSwitch);
			console.log(Object.keys(drugs)[Object.keys(drugs).length - 1]);
			setTimeout(() => {
				updatePageSwitch(pageSwitch => [...pageSwitch, (pageSwitch[pageSwitch.length - 1] + 1)]);
				console.log('This is the pageSwitch in the setTimeout: ' + pageSwitch);
			}, 3000);
			updateDrugs(newDrug);
		}
	}
	const handleDrugChange = (e) => {
		const newDrug = e.target.value;
		updateDrugs({ ...drugs, [e.target.name]: newDrug });
	}
	return (
		<div id="checkPrescriptionClipboard" className={props.className}>
			<Form className="form" id="empty-drug-fields">
				<ClipboardClip />
				{pages.map((page) => {
					return (
						<div className={
							(pageSwitch[pageSwitch.length - 1] / 2 < page)
								? ("paperUp")
								: (pageSwitch[pageSwitch.length - 1] % 2 === 0)
									? ("paperOut")
									: pageSwitch[pageSwitch.length - 1] % 2 === 1 ?
										("paperIn")
										: ("paperIn")} key={page} id={page}>
							<div className={(pageSwitch[pageSwitch.length - 1] / 2 < page) ?
								"centeredPaper" :
								"centeredForPageSwitchesPaper"}>

								<Button id="addPrescriptions" type="" onClick={appendDrug}>
									Add Prescription
												</Button>
								{page === 1 ?
									Object.keys(drugs).length < (page + 2) ?
										Object.keys(drugs).map(drug => {
											console.log(Object.keys(drugs))
											return (
												<Form.Group controlId="" key={drug}>
													<FloatingLabelInput
														id={drug}
														label="Drug"
														type="Drug"
														name={drug}
														className="changeColor"
														onChange={handleDrugChange}
													/>
												</Form.Group>
											)
										}
										) : Object.keys(drugs).slice(Object.keys(drugs).length - 2, Object.keys(drugs).length).map(drug => {
											return (
												<Form.Group controlId="" >
													<FloatingLabelInput
														id={drug}
														label="Drug"
														type="Drug"
														name={drug}
														className="changeColor"
														onChange={handleDrugChange}
													/>
												</Form.Group>
											)
										}
										)
									: page % 2 === 0 ?
										Object.keys(drugs).length === (page + (pages[(pages).length - 2])) ? //On the seventh drug, your are on the fourth page, so it skips to the next code block.
											Object.keys(drugs).slice(Object.keys(drugs).length - 1, Object.keys(drugs).length).map(drug => {
												console.log(pages[(pages).length - 1]);
												return (
													<Form.Group controlId="" >
														<FloatingLabelInput
															id={drug}
															label="Drug"
															type="Drug"
															name={drug}
															className="changeColor"
															onChange={handleDrugChange}
														/>
													</Form.Group>
												)
											}
											) : Object.keys(drugs).slice(Object.keys(drugs).length - 2, Object.keys(drugs).length).map(drug => {
												console.log(page + 1);
												return (
													<Form.Group controlId="" >
														<FloatingLabelInput
															id={drug}
															label="Drug"
															type="Drug"
															name={drug}
															className="changeColor"
															onChange={handleDrugChange}
														/>
													</Form.Group>
												)
											}
											)
										: Object.keys(drugs).length === (page + (pages[(pages).length - 2]))
											?
											Object.keys(drugs).slice(Object.keys(drugs).length - 1, Object.keys(drugs).length).map(drug => {
												return (
													<Form.Group controlId="" >
														<FloatingLabelInput
															id={drug}
															label="Drug"
															type="Drug"
															name={drug}
															className="changeColor"
															onChange={handleDrugChange}
														/>
													</Form.Group>
												)
											}
											) : Object.keys(drugs).slice(Object.keys(drugs).length - 2, Object.keys(drugs).length).map(drug => {
												return (
													<Form.Group controlId="" >
														<FloatingLabelInput
															id={drug}
															label="Drug"
															type="Drug"
															name={drug}
															className="changeColor"
															onChange={handleDrugChange}
														/>
													</Form.Group>
												)
											}
											)
								}
								<Button id="checkInteractionsSubmit" type="" onClick={handleClick}>
									Check Interactions
								</Button>
							</div>
						</div>
					);
				})}
			</Form>
		</div>
	);
}

export default CheckPrescriptionClipboard;
