import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';

class RegistrationNeedsCompletion extends React.Component{  
    constructor(props){
        super(props);
        this.registrationNeedsCompletion = this.registrationNeedsCompletion.bind(this);
    }
    //const currentUser = props.props.user;
    registrationNeedsCompletion = (e) =>{
        e.preventDefault();
        this.props.onChange(this.props.props.className = [""]);
        
    }
    render(){
        return(
            <div className={this.props.className}>
                <Form className="form">
                    <ClipboardClip/>
                    <div className="centeredPaper">
                        <p>Our records show that you have already started the registration process. Please check your email to complete your registration and then log in.</p>
                        <Button id="registrationNeedsCompletion" type="" onClick={this.registrationNeedsCompletion}>Okay</Button>
                    </div>
                </Form>
            </div>
    
        );
    }
};

export default RegistrationNeedsCompletion;