import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';
import responseContext from './ResponsesContext';

function DisplayInteractions(props) {
    const response = useContext(responseContext);
    const handleClick = (e) => {
        e.preventDefault();
        props.onChange(props.props.className = [""]);
        response.setResponse('Hello World');
    };
    return (
        <div id="displayInteractionsClipboard" className={props.className}>
            <Form className="form" >
                <ClipboardClip />
                <div className="centeredPaper">
                    <div>
                        {response.data === 'Hello World' ?
                            <p>Loading...</p>
                            :
                            (response.data.response.spellingSolutions).map(spellingSolution => {
                                return (
                                    <p>{spellingSolution}</p>
                                )
                            })
                        }
                        {response.data === 'Hello World' ?
                            <p>Loading...</p>
                            : <p>Interactions: {
                                typeof response.data.response.drugInteractions[0].fullInteractionTypeGroup === 'undefined' ?
                                    'Your search returned no results.'
                                    :
                                    JSON.stringify(response.data.response.drugInteractions[0].fullInteractionTypeGroup[0].fullInteractionType[0].interactionPair[0].description)
                            }
                            </p>
                        }
                        {response.data === 'Hello World' ?
                            <p>Loading...</p>
                            : typeof response.data.response.drugInteractions[0].fullInteractionTypeGroup === 'undefined' || response.data.response.drugInteractions[0].fullInteractionTypeGroup[0].fullInteractionType[0].interactionPair[0].severity === 'N/A'?
                                ''
                                :
                                <Button id="highSeverity">Severity:&nbsp;
                                    {(response.data.response.drugInteractions[0].fullInteractionTypeGroup[0].fullInteractionType[0].interactionPair[0].severity)}
                                </Button>
                        }
                    </div>

                    <Button id="displayInteractionsCheck" type="" onClick={handleClick} >
                        Finished
								</Button>

                </div>
            </Form>
        </div>
    );
}

export default DisplayInteractions;