import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';

class ResetConfirmationClipboard extends React.Component{
    constructor(props){
        super(props);
        this.resetConfirmationClipboard = this.resetConfirmationClipboard.bind(this);
    }
    //const currentUser = props.props.user;
    resetConfirmationClipboard = (e) =>{
        e.preventDefault();
        
        this.props.onChange(this.props.props.className = []);
        
    }
    render(){
        return(
            <div className={this.props.className} id="resetConfirmationClipboard">
                <Form className="form">
                    <ClipboardClip/>
                    <div className="centeredPaper">
                        <p>Please check your email to reset your password.</p>
                        <Button id="resetConfirmationClipboardButton" type="" onClick={this.resetConfirmationClipboard}>Okay</Button>
                    </div>
                </Form>
            </div>
    
        );
    }
};

export default ResetConfirmationClipboard;