import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';

class CompleteYouRegistration extends React.Component{  
    constructor(props){
        super(props);
        this.completeYouRegistration = this.completeYouRegistration.bind(this);
    }
    //const currentUser = props.props.user;
    completeYouRegistration = (e) =>{
        e.preventDefault();
        this.props.onChange(this.props.props.className = [""]);
        
    }
    render(){
        return(
            <div className={this.props.className} >
                <Form className="form">
                    <ClipboardClip/>
                    <div className="centeredPaper">
                        <p>Please check your email so
                            that we can verify your email account
                            and complete your registration.</p>
                        <Button id="completeYourRegistrationButton" type="" onClick={this.completeYouRegistration}>Okay</Button>
                    </div>
                </Form>
            </div>
    
        );
    }
};

export default CompleteYouRegistration;