import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';
import axios from 'axios';
import responseContext from './ResponsesContext';

const store = require('store2');

function CheckInteractionsResponses(props) {
	const response = useContext(responseContext);
	const handleClick = (e) => {
		e.preventDefault();
		props.onChange(props.props.className = ["displayInteractions"]);
	};
	return (
		<div id="checkInteractionResponseDataClipboard" className={props.className}>
			<Form className="form" >
				<ClipboardClip />
				<div className="centeredPaper">
					{response.data === 'Hello World'
						?
						<div>
							<p>Loading...</p>
						</div>
						:
						<div>
							<p>{JSON.stringify(response.data.response.drugInteractions[0].nlmDisclaimer)}</p>
							<Button id="checkInteractionResponsesDismiss" type="" onClick={handleClick} >
								Okay
								</Button>
						</div>
					}
				</div>
			</Form>
		</div>
	);
}

export default CheckInteractionsResponses;