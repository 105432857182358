import React from 'react';

function PrivacyPolicyText() {
    return (
        <div>
            <h1>Privacy Policy</h1>
            <p>Last updated June 18, 2021</p>
            <p>Thank you for choosing to be part of our community at HealthOne (“Company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please submit your feedback at the bottom of the page.</p>
            <p>When you visit our website https://healthone.io, application, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please submit your feedback at the bottom of the page.</p>
            <p>This privacy policy applies to all information collected through https://healthone.io and any related services (we refer to them collectively in this privacy policy as the "Services").</p>
            <p>Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.</p>
            <h2>Table of Contents</h2>
            <ul>
                <li><a href="#widwc">What information do we collect?</a></li>
                <li><a href="#hdwuyi">How do we use your information?</a></li>
                <li><a href="#wyibswa">Will your information be shared with anyone?</a></li>
                <li><a href="#hldwkyi">How long do we keep your information?</a></li>
                <li><a href="#hdwkyis">How do we keep your information safe?</a></li>
                <li><a href="#waypr">What are your privacy rights?</a></li>
                <li><a href="#cfdntf">Controls for do-not-track features</a></li>
                <li><a href="#dcrhspr">Do California residents have specific privacy rights?</a></li>
                <li><a href="#dwmuttp">Do we make updates to this policy?</a></li>
            </ul>

            <h2>What information do we collect?</h2>
            <h3>Personal information you disclose to us</h3>
            <p>We collect your name, email address, and password.</p>
            <p>We collect personal information that you voluntarily provide to us when registering for the services, expressing an interest in obtaining information about us and our services, when participating in activities on the services or otherwise contacting us.</p>
            <p>The personal information that we collect depends on the context of your interactions with us and the services, the choices you make, and features you use. The personal information we collect can include the following:</p>
            <ul>
                <li>Name and Contact Data: We collect your first and last name, email address, and other similar contact data.</li>
                <li>Credentials: We collect passwords, password hints, and similar security information used for authentication and account access.</li>
            </ul>
            <p>All personal information that you provide to us must be true, complete, and accurate.</p>
            <h3>Information collected through our services</h3>
            <p>We may collect information regarding your when you use our service.</p>
            <span id="hdwuyi"></span>
            <p>If you use our Apps, we will collect information during the use of our apps, but we do not store this information or link it to your account with us. We use third party APIs, and we do not disclose any information that you provide to us to those API providers. We also use cookies to store your session ID when you are logged in to our services, but we actively use no other cookies.</p>
            <h2>How do we use your information?</h2>
            <p>We process your information that you provide based on your personal interest in your health. This information is yours, and we do not link that information to your account when sharing it with our API providers. We may provide some information if legally obligated, but to protect your privacy, we have limited our capacity in this respect.</p>
            <p>We use personal information collected via our service for the reasons described below. We process your personal information for these purposes at your request with your consent and to comply with any legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</p>
            <p>We use the information we collect or receive for the following reasons:</p>
            <span id="wyibswa"></span>
            <ul>
                <li>To manage user accounts, we may use your information for the purposes of managing your account and keeping it in working order. We will not use identifiable personal information without your consent.</li>
            </ul>
            <h2>Will your information be shared with anyone?</h2>
            <p>We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>
            <p>We may process or share data based on the following legal basis:</p>
            <p></p>
            <ul>
                <li>Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose.</li>
                <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
                <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena, including in response to public authorities to meet national security or law enforcement requirements.</li>
                <li>Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
                <span id="hldwkyi"></span>
                <li>Threats to Other Users of the Service: We may process private information in the event that we suspect that you have or have attempted to access another users account or have attempted to sabotage the service as a whole.</li>
            </ul>
            <h2>How long do we keep your information?</h2>
            <p>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</p>
            <span id="hdwkyis"></span>
            <p>We will delete your personal information as soon as you delete your account and after the minimum legally required amount of time has passed.</p>
            <h2>How do we keep your information safe?</h2>
            <p>We aim to protect your personal information through a system of organizational and technical security measures.</p>
            <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal <span id="waypr"></span>information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our service is at your own risk. You should only access the service within a secure environment.</p>
            <h2>What are your privacy rights?</h2>
            <p>You may review, change, or terminate your account at any time.</p>
            <p>If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</p>
            <h3>Account Information</h3>
            <p>If you would at any time like to review or change the information in your account or terminate your account, you can do the following:</p>
            <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use, and comply with legal requirements. If you wish to terminate your account, please provide us with your first name, last name, and email address.</p>
            <span id="cfdntf"></span>
            <p>Uses of Your Email Address: We only email you to confirm your email address when you sign up or reset your password.</p>
            <h2>Controls for do-not-track features</h2>
            <p>We do not track your location.</p>
            <h2 id="dcrhspr">Do California residents have specific privacy rights?</h2>
            <p>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</p>
            <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. We do not disclose your information to third parties on this site for marketing purposes. We do send your information to third party API Providers, but the information is not associated with your name or account in their systems. If you are a California resident and would like ask for a confirmation that we have not shared your information, please submit your request in writing to us using the contact information provided below.</p>
            <p>If you are under 18 years of age, reside in California, and have a registered account with the services, you have the right to request removal of unwanted data that you publicly post on the service. We do not currently allow users to post any information at this time. To request removal of any data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. Please be aware that the data may not be completely or comprehensively removed from our systems.</p>
            <span id="dwmuttp"></span>
            <h2>Do we make updates to this policy?</h2>
            <p>Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
            <p>We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</p>
        </div>
    )
}
export default PrivacyPolicyText;


