import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './ClipboardClip';

const store = require('store2');

function LogOutClipboard(props) {
	const handleClick = (e) => {
		e.preventDefault();
		props.onChange(
			props.props.user = {
				loggedIn: false,
				email: '',
				password: '',
				firstName: ''
			},
		);
		store.clearAll();
	};
	return (
		<div id="logOutClipboard" className={props.className}>
			<Form className="form">
				<ClipboardClip />
				<div className="centeredPaper">
					<Button id="LogOut" type="" onClick={handleClick}>
						Log Out
					</Button>
				</div>
			</Form>
		</div>
	);
}

export default LogOutClipboard;
