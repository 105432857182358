import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import '../App.css';
import '../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../img/logoIteration4TransparentBackground4Matte.gif';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogInClipboard from './loginComponents/logInClipboard';
import RegisterClipboard from './registrationComponents/registerClipboard';
import ResetClipboard from './resettingPasswordComponents/resetClipboard';
import LogOutClipboard from './LogOutClipBoard';
import CheckPrescriptionsClipboard from './interactionComponents/CheckPrescriptionClipboard';
import CheckSymptomsClipboard from './checkSymptomsComponents/CheckSymptomsClipboard';
import GetRegistrationVariableTrigger from './registrationComponents/GetRegistrationVariableTrigger';
import GetPasswordReset from './resettingPasswordComponents/GetPasswordReset';
import IncorrectUsernameOrPassword from './loginComponents/IncorrectUsernameOrPassword';
import RegistrationNotComplete from './registrationComponents/RegistrationNotComplete';
import ErrorOnResettingPassword from './resettingPasswordComponents/ErrorOnResettingPassword';
import CompleteYourRegistrationClipboard from './registrationComponents/CompleteYourRegistrationClipboard';
import RegistrationNeedsCompletion from './registrationComponents/RegistrationNeedsCompletion';
import SomethingWentWrongWithYourRegistration from './registrationComponents/SomethingWentWrongWithYourRegistration';
import ResetConfirmationClipboard from './resettingPasswordComponents/ResetConfirmationClipboard';
import ResetPasswordFailure from './resettingPasswordComponents/ResetPasswordFailure';
import ErrorForResettingPassword from './resettingPasswordComponents/ErrorForResettingPassword';
import CheckInteractionResponses from './interactionComponents/CheckInteractionResponses';
import DisplayInteractions from './interactionComponents/DisplayInteractions';
import responseContext from './interactionComponents/ResponsesContext';
import CheckSymptoms from './checkSymptomsComponents/CheckSymptoms';
import SymptomsContext from './checkSymptomsComponents/SymptomsContext';
import ShowTreatments from './checkSymptomsComponents/ShowTreatments';
import TreatmentContext from './checkSymptomsComponents/TreatmentContext';
import FeedbackForm from './feedbackComponents/FeedbackForm';
import ThankYouForYourFeedback from './feedbackComponents/ThankYouForYourFeedback';
import ErrorFromYourFeedback from './feedbackComponents/ErrorFromYourFeedback';
import PrivacyPolicy from './privacyPolicyComponents/PrivacyPolicy';
import PrivacyPolicyForRegistration from './privacyPolicyComponents/PrivacyPolicyForRegistration';
import LogOutForDisagreement from './privacyPolicyComponents/LogOutForDisagreement';
import NoRegisteredUser from './registrationComponents/NoRegisteredUser';


const store = require('store2');

const style = {
	borderRadius: '.25rem',
	paddingRight: '7px',
	paddingLeft: '7px',
	marginLeft: '5.25px',
	marginRight: '5.25px',
	backgroundColor: '#ECFBFF',
	paddingBottom: '.65rem',
	paddingTop: '.65rem',
	textAlign: 'center',
	display: 'inline-block',
	fontFamily: 'Aktiv Grotesk Extended Bold',
	color: '#212529',
	cursor: 'pointer',
	border: '1px solid transparent',
	marginTop: '.375rem',
	marginBottom: '.375rem'
};

const HealthOneNavbar = (props) => {
	const [response, updatedResponse] = useState('Hello World');
	const [symptomResponse, setSymptomResponse] = useState('Hello World');
	const [treatementResponse, setTreatmentResponse] = useState('Hello Fucking World');

	const [user, setUser] = useState(props.props.user);
	function userStatusChange(updateUser) {
		setUser(updateUser);
		//console.log(updateUser.loggedIn);
		if (updateUser.loggedIn === true) {
			props.onChange({
				user: updateUser,
				className
			});
		} else if (updateUser.loggedIn === false) {
			props.props.onChange({
				user: updateUser,
				className
			});
		}
	}
	function userLoggingOut(updateUser) {
		setUser(updateUser);
		props.onChange({
			updateUser,
			className: ['']
		});
	}
	useEffect(() => {
		/* Use this for troubleshooting later if necessare. This logs the time in the session, the current time, and the different between the two. 
		console.log('This is the time in the store: ' + store.session.get('timeOfLogin'));
		console.log('This is the time right now: ' + new Date().getTime());
		console.log('This is the difference between the time in the store and the time right now: ' + (store.session.get('timeOfLogin') - new Date().getTime()));*/
		if (store.session.get('timeOfLogin')) {
			setTimeout(() => {
				store.clearAll();
				setUser({
					user: {
						loggedIn: store.session.get('id'),
						email: store.session.get('email'),
						password: '',
						firstName: store.session.get('firstName')
					},
					className: []
				});
			}, (store.session.get('timeOfLogin') - new Date().getTime()));
		}
	});
	const [className, setClassName] = useState(props.props.className);
	function setTheClassNameForTheClipboard(theClipboardsClassName) {
		setClassName(theClipboardsClassName);
		props.onChange({
			user,
			className: theClipboardsClassName
		});
	}
	function setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut(theClipboardsClassName) {
		setClassName(theClipboardsClassName);
		props.onChange({
			user: {
				loggedIn: false,
				email: '',
				password: '',
				firstName: ''
			},
			className: theClipboardsClassName
		});
	}
	const params = new URLSearchParams(window.location.search);
	if (params.has('passwordreset')) {
		props.props.className = ['newPasswordClassName'];
	}
	const handleClick = (event) => {
		const clipboardMoving = event.currentTarget.attributes.clipboardmoving.value;
		if (props.props.className[0] === clipboardMoving) {
			props.props.className = [''];
			props.onChange({
				user,
				className: props.props.className
			});
		} else if (props.props.className[0] !== clipboardMoving) {
			props.props.className = [clipboardMoving];
			props.onChange({
				user,
				className: props.props.className
			});
		}
	};
	const handleMenuClick = (event) => {
		if (props.props.className !== []) {
			props.onChange({
				user,
				className: []
			});
		}
	};
	return (
		<div className="Navbar">
			<responseContext.Provider
				value={{ data: response, setResponse: updatedResponse }}
			>
				<SymptomsContext.Provider
					value={{ data: symptomResponse, setResponse: setSymptomResponse }}
				>
					<TreatmentContext.Provider
						value={{ data: treatementResponse, setResponse: setTreatmentResponse }}
					>
						{user.loggedIn ? (
							<div>
								<Navbar collapseOnSelect className="shadow" expand="lg">
									<Navbar.Brand style={{ paddingTop: 0, paddingBottom: 0 }}>
										<img src={logo} alt="Health One Logo" id="logoImage" />
									</Navbar.Brand>
									<Navbar.Toggle
										id="menu-toggle"
										aria-controls="responsive-navbar-nav"
										className=""
										onClick={handleMenuClick}
										target="clipboard"
										disabled={
											props.props.className.includes('privacyPolicyClipboardAgreement') ? (
												true
											) : props.props.className.includes('logOutForDisagreement') ?
												true
												: (
													false
												)
										}
									/>
									<Navbar.Collapse id="responsive-navbar-nav">
										<Nav className="mr-auto">
											<Nav.Link
												className="shadow"
												variant="light"
												style={style}
												id="checkPrescription"
												onClick={handleClick.bind()}
												value="Check Prescription"
												clipboardmoving="checkPrescriptionClipboard"
												href="#"
												disabled={
													props.props.className.includes('privacyPolicyClipboardAgreement') ? (
														true
													) : props.props.className.includes('logOutForDisagreement') ?
														true
														: (
															false
														)
												}
											>
												Check Prescription
												<FontAwesomeIcon icon={faCaretDown} />
											</Nav.Link>
											<Nav.Link
												className="shadow"
												variant="light"
												style={style}
												onClick={handleClick.bind()}
												value="Check Symptoms"
												clipboardmoving="checkSymptomsClipboard"
												href="#"
												disabled={
													props.props.className.includes('privacyPolicyClipboardAgreement') ? (
														true
													) : props.props.className.includes('logOutForDisagreement') ?
														true
														: (
															false
														)
												}
											>
												Check Symptoms
												<FontAwesomeIcon icon={faCaretDown} />
											</Nav.Link>
											<Nav.Link
												className="shadow"
												variant="light"
												style={style}
												id="feedbackButton"
												onClick={handleClick.bind()}
												value="Feedback"
												clipboardmoving="feedbackForm"
												href="#"
												disabled={
													props.props.className.includes('privacyPolicyClipboardAgreement') ? (
														true
													) : props.props.className.includes('logOutForDisagreement') ?
														true
														: (
															false
														)
												}
												props={props.props}
											>
												Feedback
												<FontAwesomeIcon icon={faCaretDown} />
											</Nav.Link>
											<Nav.Link
												className="shadow"
												variant="light"
												style={style}
												id="logOutButton"
												onClick={handleClick.bind()}
												value="Log Out"
												clipboardmoving="logOutClipboardClassName"
												href="#"
												disabled={
													props.props.className.includes('privacyPolicyClipboardAgreement') ? (
														true
													) : props.props.className.includes('logOutForDisagreement') ?
														true
														: (
															false
														)
												}
											>
												Log Out
												<FontAwesomeIcon icon={faCaretDown} />
											</Nav.Link>
										</Nav>
									</Navbar.Collapse>
								</Navbar>
								<LogOutClipboard
									onChange={userLoggingOut}
									className={
										props.props.className.includes('logOutClipboardClassName') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<CheckPrescriptionsClipboard
									onChange={setTheClassNameForTheClipboard}
									className={
										props.props.className.includes('checkPrescriptionClipboard') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<CheckSymptomsClipboard
									onChange={setTheClassNameForTheClipboard}
									className={
										props.props.className.includes('checkSymptomsClipboard') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<CheckInteractionResponses
									onChange={setTheClassNameForTheClipboard}
									className={
										props.props.className.includes('checkInteractionResponses') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<DisplayInteractions
									onChange={setTheClassNameForTheClipboard}
									className={
										props.props.className.includes('displayInteractions') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<CheckSymptoms
									onChange={setTheClassNameForTheClipboard}
									className={
										props.props.className.includes('checkSymptoms') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<ShowTreatments
									onChange={setTheClassNameForTheClipboard}
									className={
										props.props.className.includes('showTreatments') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<FeedbackForm
									onChange={setTheClassNameForTheClipboard}
									className={
										props.props.className.includes('feedbackForm') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<ThankYouForYourFeedback
									onChange={setTheClassNameForTheClipboard}
									className={
										props.props.className.includes('thankYouForYourFeedback') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<ErrorFromYourFeedback
									onChange={setTheClassNameForTheClipboard}
									className={
										props.props.className.includes('errorFromYourFeedback') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<PrivacyPolicyForRegistration
									onChange={setTheClassNameForTheClipboard}
									className={
										props.props.className.includes('privacyPolicyClipboardAgreement') ? (
											'clipboard clipboard-showing modalShadow'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<LogOutForDisagreement
									onChange={userLoggingOut}
									className={
										props.props.className.includes('logOutForDisagreement') ? (
											'clipboard clipboard-showing modalShadow'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<header className="App-header">
									<div>
										<h1>
											Welcome {user.firstName}!
										</h1>
									</div>
								</header>

							</div>
						) : (
							<div>
								<Navbar collapseOnSelect className="shadow" expand="lg">
									<Navbar.Brand style={{ paddingTop: 0, paddingBottom: 0 }}>
										<img src={logo} alt="Health One Logo" id="logoImage" />
									</Navbar.Brand>
									<Navbar.Toggle
										id="menu-toggle"
										aria-controls="responsive-navbar-nav"
										className=""
										onClick={handleMenuClick}
										target="clipboard"
									/>
									<Navbar.Collapse id="responsive-navbar-nav">
										<Nav className="mr-auto">
											<Nav.Link
												className="shadow"
												variant="light"
												style={style}
												id="logInButton"
												onClick={handleClick.bind()}
												value="Log In"
												clipboardmoving="logInClipboardClassName"
												href="#"
											>
												Log In
												<FontAwesomeIcon icon={faCaretDown} />
											</Nav.Link>
											<Nav.Link
												className="shadow"
												variant="light"
												style={style}
												id="registerButton"
												onClick={handleClick.bind()}
												clipboardMoving="registerClipboardClassName"
												href="#"
											>
												Register
												<FontAwesomeIcon icon={faCaretDown} />
											</Nav.Link>
											<Nav.Link
												className="shadow"
												variant="light"
												style={style}
												id="resetButton"
												onClick={handleClick.bind()}
												clipboardmoving="resetClipboardClassName"
												href="#"
											>
												Reset Password
												<FontAwesomeIcon icon={faCaretDown} />
											</Nav.Link>
											<Nav.Link
												className="shadow"
												variant="light"
												style={style}
												id="privacyPolicyButton"
												onClick={handleClick.bind()}
												clipboardmoving="privacyPolicyClipboard"
												href="#"
											>
												Privacy Policy
												<FontAwesomeIcon icon={faCaretDown} />
											</Nav.Link>
										</Nav>
									</Navbar.Collapse>
								</Navbar>
								<LogInClipboard
									onChange={userStatusChange}
									className={
										props.props.className.includes('logInClipboardClassName') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<RegisterClipboard
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('registerClipboardClassName') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<ResetClipboard
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('resetClipboardClassName') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<GetRegistrationVariableTrigger
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('thankYouForRegistering') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}

									props={props.props}>
								</GetRegistrationVariableTrigger>
								<GetPasswordReset
									onChange={setTheClassNameForTheClipboard}
									className={
										props.props.className.includes('newPasswordClassName') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}></GetPasswordReset>
								<IncorrectUsernameOrPassword
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('incorrectUsernameOrPasswordClassname') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}></IncorrectUsernameOrPassword>
								<RegistrationNotComplete
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('registrationNotComplete') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}></RegistrationNotComplete>
								<ErrorOnResettingPassword
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('errorOnResetPasswordNotComplete') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}>
								</ErrorOnResettingPassword>
								<CompleteYourRegistrationClipboard
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('completeYourRegistrationClipboard') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}>
								</CompleteYourRegistrationClipboard>
								<RegistrationNeedsCompletion
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('registrationNeedsCompletion') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}>
								</RegistrationNeedsCompletion>
								<SomethingWentWrongWithYourRegistration
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('somethingWentWrongWithYourRegistration') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}>
								</SomethingWentWrongWithYourRegistration>
								<ResetConfirmationClipboard
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('resetConfirmationClipboard') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}>
								</ResetConfirmationClipboard>
								<ResetPasswordFailure
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('resetPasswordFailureClipboard') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}>
								</ResetPasswordFailure>
								<ErrorForResettingPassword
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('errorForResettingPassword') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								></ErrorForResettingPassword>
								<PrivacyPolicy
									onChange={setTheClassNameForTheClipboard}
									className={
										props.props.className.includes('privacyPolicyClipboard') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								>
								</PrivacyPolicy>
								<NoRegisteredUser
									onChange={setTheClassNameForTheClipboardAndLogTheUserOutOrKeepTheUserLoggedOut}
									className={
										props.props.className.includes('noRegisteredUser') ? (
											'clipboard clipboard-showing'
										) : (
											'clipboard clipBoard-retracted'
										)
									}
									props={props.props}
								/>
								<header className="App-header">
									<div>
										<h1 className="landingHeading">
											HealthOne
										</h1>
										<div className="aboutTheSite">
											<h2>Diagnoses and Drug Interactions</h2>
											<p>
												With HealthOne, you can respond quickly to immediate medical needs.
												HealthOne provides an interface for both mobile and desktop devices to access information on drug interactions and diagnoses of symptoms.
												It uses trusted APIs to deliver this information to you in a digestible format with workflows designed to give you the information
												that you need.
											</p>
											<p>
												Due to limited resources, we do ask that you log in.
												We want to track the usage of the site and determine the need for more resources.
												We do not track any of your entries
												once you are logged in, including any of the information
												you enter
												when filling out medical information.
												You can read the privacy policy for more information.
												We do ask for your feedback when you can provide it,
												but you can provide that at your discretion.
											</p>
											<p>
												We provide the information on this site through RxNav, created by the <abbr title="National Library of Medicine">NLM</abbr> and the <abbr title="National Institue of Health">NIH</abbr>, and APIMedic APIs.
												With these trusted resources, we hope to provide you with information you need to make an informed decision.
												We can only provide preliminary information available in these systems.
												Please consult with a medical professional about any health concerns
												that you may have.
											</p>
										</div>
									</div>
								</header>
							</div>

						)}
					</TreatmentContext.Provider>
				</SymptomsContext.Provider>
			</responseContext.Provider>

		</div>
	);
};

export default HealthOneNavbar;
