import React, { useState, useRef, useEffect, useContext } from 'react';
import {
	/* These work with the v2 component.
	EReCaptchaV2Size,
	EReCaptchaV2Theme,
	ReCaptchaV2,*/
	ReCaptchaV3
} from 'react-recaptcha-x';
//This is the starter for the components

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabelInput from 'react-floating-label-input';
import ClipboardClip from './../ClipboardClip';
import axios from 'axios';
import Overlay from 'react-bootstrap/Overlay';

const store = require('store2');
//let v2Token;
let v3Token;
let v3GoogleRecaptchaScore;
const timeOfLogIn = new Date();
function LogInClipboard(props) {
	const [state, setState] = useState({
		user: {
			loggedIn: false,
			email: '',
			password: '',
			firstName: ''
		},
		className: []
	});
	const [target, setTarget] = useState(null);
	const overlayRef = useRef(null);
	const [userPassword, userPasswordCapsLock] = useState(true);
	/* I saved this for later if needed. It's to use Google ReCaptcha v2.
	const v2Callback = (token) => {
		if (typeof token === 'string') {
			console.log('this is the v2 token', token);
			v2Token = token;
		} else if (typeof token === 'boolean' && !token) {
			console.log('token has expired, user must check the checkbox again');
		} else if (token instanceof Error) {
			console.log('error. please check your network connection');
		}
	};
	*/
	//This might have to go in a separate component? 
	const v3Callback = (token, refreshToken) => {
		if (typeof token === 'string') {
			//Keep this for trouble-shooting later. 
			//console.log('This is the v3 token on the login component: ', token);
			/* 
			* This block of code gets the recaptcha to fail?
			* console.log('This is the reCaptcha key in local storage: ' + localStorage.getItem('_grecaptcha'));
			*/
			v3Token = token;
			//I set this to 15 seconds because that's the fastest I can attempt to log in with the wrong password and then retry with the right password. 
			setTimeout(function () { refreshToken(); }, 15000);// I think you can use 15 or so seconds here as a fallback if you can't get the token to refresh on failure. 
			/* This doesn't work
			if(props.className === "logInClipboardClassName"){
				refreshToken();	
			}*/
			/*if( v3GoogleRecaptchaScore === undefined){
				refreshToken();
			}*/
			console.log('This is the token for the google recaptcha score: ' + !v3GoogleRecaptchaScore);
			console.log('This is the token refreshing at intervals' + token);
			//console.log('This is the token for the reCaptcha on GitHub.')
			/* This is for trouble-shootin later if necessary.
			if (typeof refreshToken === 'function') {
				console.log('this is the refresh token function', refreshToken);
				//refreshToken();

			}*/
		} /* This is for trouble-shooting later if necessary. 
		else {
			console.log('token retrieval in progress...');
		}*/
	};
	//const v3Callback = v3Callback();
	const emailRef = useRef();
	const passwordRef = useRef();

	async function verifyGoogle3Captcha() {
		const getTheGoogleScore =
			axios
				.post('googleCaptchaVerify?response=' + v3Token)
				.then((response) => {
					/*console.log('This is the google recaptcha v3 response: ' + JSON.stringify(response.data.score));*/
					v3GoogleRecaptchaScore = response.data.score;

				})
				.catch((error) => {
					console.log(error);
				})
		await getTheGoogleScore;
	};
	const handleClick = (e) => {
		e.preventDefault();
		props.props.user.email = state.user.email;
		props.props.user.password = state.user.password;
		/* console.log('This is the v2 token in the handle click function: ' + v2Token);
		*  Use this to check if the v2 token makes it to the handleClick function.
		*/
		async function loginWithCaptcha() {
			await verifyGoogle3Captcha();
			await console.log('This is the google recaptcha score after the await for the login component: ' + v3GoogleRecaptchaScore);
			async function checkScore() {
				if (v3GoogleRecaptchaScore < 0.1 || v3GoogleRecaptchaScore === undefined) {
					removeEmailValue();
					changeClassNameToNoRegisteredUser();
				} else if (v3GoogleRecaptchaScore >= 0.1) {
					/*console.log('This is if the token is available');
					* Use this to check if the v2 token is available.
					*/
					axios
						.post('login', props.props.user)
						.then((response) => {
							if (response.data == 'NotRegistered') {
								notRegisteredYet();
								removeEmailValue();
							} else if (response.data === 'Failure') {
								changeClassNameToIncorrectError();
								removeEmailValue();
							} else if (response.data === 'No user found') {
								removeEmailValue();
								changeClassNameToNoRegisteredUser();
							} else if (response.data.secret) {
								timeOfLogIn.setMinutes(timeOfLogIn.getMinutes() + 15);
								store.session('timeOfLogin', timeOfLogIn.getTime());
								store.session('id', response.data.secret);
								store.session('email', response.data.email);
								store.session('firstName', response.data.firstName);
								props.onChange(props.props.user = {
									loggedIn: response.data.loggedIn,
									email: response.data.email,
									password: props.props.user.password,
									firstName: response.data.firstName
								});
							}
						})
						.catch((error) => {
							console.log(error);
						});
				}

			};
			await checkScore();
		}
		loginWithCaptcha();
	};
	const handleEmailChange = (event) => {
		setState({
			user: {
				loggedIn: state.user.loggedIn,
				email: event.target.value,
				password: state.user.password,
				firstName: state.user.firstname
			},
			className: props.props.className,
		});
	};
	const passwordOverlay =
		<Overlay
			show={!userPassword}
			target={target}
			placement="bottom"
			container={overlayRef.current}
		>
			<div className="warningTextOnUserInput">
				Your caps lock is on.
			</div>
		</Overlay>;
	const password = document.querySelector('#userPassword');
	if (password === null) {

	} else {
		password.addEventListener('keyup', function (e) {
			if (e.getModifierState('CapsLock')) {
				console.log('Your caps lock is on.');
				userPasswordCapsLock(false);
				setTarget(passwordRef);
			} else {
				userPasswordCapsLock(true);
			}

		})
	}
	const handlePasswordChange = (e) => {
		setState({
			user: {
				loggedIn: state.user.loggedIn,
				email: state.user.email,
				password: e.target.value,
				firstName: state.user.firstname
			},
			className: props.props.className,
		});

	};
	const removeEmailValue = () => {
		setState({
			user: {
				loggedIn: state.user.loggedIn,
				email: '',
				password: '',
				firstName: state.user.firstname
			},
			className: props.props.className,
		});
		emailRef.current.focus();
		emailRef.current.blur();
		passwordRef.current.focus();
	};
	const notRegisteredYet = () => {
		props.onChange(props.props.className = ["registrationNotComplete"]);
	}
	const changeClassNameToIncorrectError = () => {
		props.onChange(props.props.className = ["incorrectUsernameOrPasswordClassname"]);
	}
	const changeClassNameToNoRegisteredUser = () => {
		props.onChange(props.props.className = ["noRegisteredUser"]);
	}
	return (

		<div id="logInClipboard" className={props.className}>
			<Form className="form" >

				<ClipboardClip />
				<div className="centeredPaper">
					<Form.Group controlId="ControlInput">
						<FloatingLabelInput
							id="userEmail"
							label="Email"
							type="email"
							name="email"
							className="changeColor"
							onChange={handleEmailChange}
							value={(state.user.email) ? state.user.email : ''}
							refs={emailRef}
						/>
					</Form.Group>
					<div ref={overlayRef}>
						{passwordOverlay}
						<Form.Group controlId="formBasicPassword">
							<FloatingLabelInput
								id="userPassword"
								label="Password"
								type="password"
								name="password"
								onChange={handlePasswordChange}
								value={state.user.password}
								refs={passwordRef}
							/>
						</Form.Group>
					</div>
					{/* I left this here in case I need a fallback in the future. 
					<ReCaptchaV2
						callback={v2Callback}
						theme={EReCaptchaV2Theme.Light}
						size={EReCaptchaV2Size.Normal}
						id="my-id"
						data-test-id="my-test-id"
						tabindex={0}
					/>*/}
					<ReCaptchaV3 action="submit" callback={v3Callback} />
					<Button id="logInSubmit" type="submit" onClick={handleClick} >
						Log In
					</Button>

				</div>



			</Form>
		</div>

	);
}

export default LogInClipboard;