import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';

class UnableToResetPassword extends React.Component{  
    constructor(props){
        super(props);
        this.passwordNotReset = this.passwordNotReset.bind(this);
    }
    //const currentUser = props.props.user;
    passwordNotReset = (e) =>{
        e.preventDefault();
        
        this.props.onChange(this.props.props.className = []);  
        
    }
    render(){
        return(
            <div className={this.props.className} id="unableToResetRegistration">
                <Form className="form">
                    <ClipboardClip/>
                    <div className="centeredPaper">
                        <p>Dang it! Something went wrong. Please check your email and use the link in the email that we sent you to reset your password. You must use a password that's different from you old password.</p>
                        <Button id="unableToResetPasswordButton" type="" onClick={this.passwordNotReset}>Dismiss</Button>
                    </div>
                </Form>
            </div>
    
        );
    }
};

export default UnableToResetPassword;