import React from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';
import axios from 'axios';



class GetRegistrationVariableTrigger extends React.Component {
    constructor(props) {
        super(props);
        this.thankYouForCompletingYourRegistration = this.thankYouForCompletingYourRegistration.bind(this);
        this.weCouldNotFindYourRegistration = this.weCouldNotFindYourRegistration.bind(this);
        this.state = { userRegistered: '' };
    }
    thankYouForCompletingYourRegistration = (e) => {
        e.preventDefault();
        this.setState({ className: ["logInClipboardClassName"] });
        this.props.onChange(this.props.props.className = ["logInClipboardClassName"]);
        //this.props.props.className = this.state.className;

    }
    weCouldNotFindYourRegistration = (e) =>{
        e.preventDefault();
        this.setState({className: ["registerClipboardClassName"]});
        this.props.onChange(this.props.props.className = ["registerClipboardClassName"]);

    }
    findTheParameterIfAvailable = () => {
        this.setState({ className: ["thankYouForRegistering"] });
        this.props.onChange(this.props.props.className = this.state.className);
    };
    getParameterAndRegisterUser = () => {
        const params = new URLSearchParams(window.location.search);
        const registrationVariable = params.get('variable');
        //console.log(params);
        if (params.has('variable')) {
            axios
                .post('completeRegistration', { "variable": registrationVariable })
                .then((response) => {
                    //console.log(response);
                    this.findTheParameterIfAvailable();
                    //console.log(response.data.affectedRows);
                    //console.log(response.data.affectedRows === 1);
                    if (response.data.affectedRows === 1) {
                        this.setState(prevState => ({ userRegistered: 0 }));
                        //Get the registration variable and get people to log in. 
                    } else if (response.data === 'We do not have a record of you.') {
                        //Return an error that they need to register if you can't find the variable. 
                        this.setState(prevState => ({ userRegistered: 2 }));
                    } else if (response.data[0].Registered === 1) {
                        this.setState(prevState => ({ userRegistered: 1 }));
                        //Show that they are already registered.
                    }
                })
                .catch((error) => {
                    //console.log(error);
                });
        }

    }
    render() {
        this.getParameterAndRegisterUser();
        //console.log(this.state.userRegistered);
        return (
            <div className={this.props.className} id="thankYouForRegistering">
                <ClipboardClip />
                <Form className="form">
                    <div className="centeredPaper">
                        {(this.state.userRegistered === 0) ? (
                            <div className="centeredPaper">
                                <p>Thank you for completing your registration. Please log in.</p>
                                <Button id="thankYouForRegisteringButton" type="" onClick={this.thankYouForCompletingYourRegistration}>Log In</Button>
                            </div>
                        ) : (
                                (this.state.userRegistered === 1)
                                    ?
                                    (
                                        <div className="centeredPaper">
                                            <p>Your are already registered. Please log in.</p>
                                            <Button id="youAreAlreadyRegisteredButton" type="" onClick={this.thankYouForCompletingYourRegistration}>Log In</Button>
                                        </div>
                                    ) : (
                                        <div className="centeredPaper">
                                            <p>We do not have a record of you. Please register and click the link in your email to sign in.</p>
                                            <Button id="youHaveNotYetRegisteredButton" type="" onClick={this.weCouldNotFindYourRegistration}>Register</Button>
                                        </div>
                                    ))}
                    </div>
                </Form>
            </div>

        );
    }
}

export default GetRegistrationVariableTrigger;
