import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ClipboardClip from './../ClipboardClip';

const store = require('store2');

function LogOutForDisagreement(props) {
	const handleClick = (e) => {
		e.preventDefault();
		props.onChange(
			props.props.user = {
				loggedIn: false,
				email: '',
				password: '',
				firstName: ''
			},
		);
		store.clearAll();
		
	};
	return (
		<div id="logOutForDisagreementClipboard" className={props.className}>
			<Form className="form">
				<ClipboardClip />
				<div className="centeredPaper">
					<p>Sorry, you must agree to our privacy policy to access the site. You must now log out and log back in to agree to the terms.</p>
					<Button id="logOutForDisagreement" type="" onClick={handleClick}>
						Log Out
					</Button>
				</div>
			</Form>
		</div>
	);
}

export default LogOutForDisagreement;
